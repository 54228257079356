// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter {
    background: linear-gradient(-45deg, #aed8ff, #ffffff 50%);
}

.counter-user,
.counter-partner {
    color: #005d8c;
    font-weight: bold;
    font-size: 35px;
    font-family: "Poppins";
    opacity: 0;
    /* Initial opacity set to 0 */
    transform: translateY(20px);
    /* Initial position to animate from */
    transition: opacity 1s, transform 1s;
}

.text-user,
.text-partner {
    color: #008ad0;
    font-size: 25px;
    font-family: "Poppins";
    opacity: 0;
    /* Initial opacity set to 0 */
    transform: translateY(20px);
    /* Initial position to animate from */
    transition: opacity 1s, transform 1s;
}

/* Animation classes to apply when in viewport */
.count-up {
    opacity: 1;
    transform: translateY(0);
}

.fade-in {
    opacity: 1;
    transform: translateY(0);
}`, "",{"version":3,"sources":["webpack://./src/style/Counter.css"],"names":[],"mappings":"AAAA;IACI,yDAAyD;AAC7D;;AAEA;;IAEI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,sBAAsB;IACtB,UAAU;IACV,6BAA6B;IAC7B,2BAA2B;IAC3B,qCAAqC;IACrC,oCAAoC;AACxC;;AAEA;;IAEI,cAAc;IACd,eAAe;IACf,sBAAsB;IACtB,UAAU;IACV,6BAA6B;IAC7B,2BAA2B;IAC3B,qCAAqC;IACrC,oCAAoC;AACxC;;AAEA,gDAAgD;AAChD;IACI,UAAU;IACV,wBAAwB;AAC5B;;AAEA;IACI,UAAU;IACV,wBAAwB;AAC5B","sourcesContent":[".counter {\n    background: linear-gradient(-45deg, #aed8ff, #ffffff 50%);\n}\n\n.counter-user,\n.counter-partner {\n    color: #005d8c;\n    font-weight: bold;\n    font-size: 35px;\n    font-family: \"Poppins\";\n    opacity: 0;\n    /* Initial opacity set to 0 */\n    transform: translateY(20px);\n    /* Initial position to animate from */\n    transition: opacity 1s, transform 1s;\n}\n\n.text-user,\n.text-partner {\n    color: #008ad0;\n    font-size: 25px;\n    font-family: \"Poppins\";\n    opacity: 0;\n    /* Initial opacity set to 0 */\n    transform: translateY(20px);\n    /* Initial position to animate from */\n    transition: opacity 1s, transform 1s;\n}\n\n/* Animation classes to apply when in viewport */\n.count-up {\n    opacity: 1;\n    transform: translateY(0);\n}\n\n.fade-in {\n    opacity: 1;\n    transform: translateY(0);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
