// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    background: linear-gradient(180deg, #4fb1ee44 10%, #E7F4FC00);
    top: 0;
    background-position: top center;
    background-repeat: repeat;
    background-size: cover;
}


.home-section {
    height: 70vh;
    fontFamily: "Poppins",
}

.home-title {
    color: #005D8C;
    font-weight: bold;
    fontFamily: "Poppins",
}

.home-title2 {
    color: #008AD0;
    font-weight: bold;
    fontFamily: "Poppins",
}

.home-subtitle {
    color: #008AD0;
    margin-top: 24px;
    margin-bottom: 24px;
    fontFamily: "Poppins",
}

.home-description {
    /* width: 80%; */
}

.home-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    fontFamily: "Poppins",
}
`, "",{"version":3,"sources":["webpack://./src/style/Home.css"],"names":[],"mappings":"AAAA;IACI,6DAA6D;IAC7D,MAAM;IACN,+BAA+B;IAC/B,yBAAyB;IACzB,sBAAsB;AAC1B;;;AAGA;IACI,YAAY;IACZ;AACJ;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB;AACJ;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB;AACJ;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,mBAAmB;IACnB;AACJ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB;AACJ","sourcesContent":[".home {\n    background: linear-gradient(180deg, #4fb1ee44 10%, #E7F4FC00);\n    top: 0;\n    background-position: top center;\n    background-repeat: repeat;\n    background-size: cover;\n}\n\n\n.home-section {\n    height: 70vh;\n    fontFamily: \"Poppins\",\n}\n\n.home-title {\n    color: #005D8C;\n    font-weight: bold;\n    fontFamily: \"Poppins\",\n}\n\n.home-title2 {\n    color: #008AD0;\n    font-weight: bold;\n    fontFamily: \"Poppins\",\n}\n\n.home-subtitle {\n    color: #008AD0;\n    margin-top: 24px;\n    margin-bottom: 24px;\n    fontFamily: \"Poppins\",\n}\n\n.home-description {\n    /* width: 80%; */\n}\n\n.home-image {\n    max-width: 100%;\n    max-height: 100%;\n    object-fit: contain;\n    fontFamily: \"Poppins\",\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
