// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon {
    width: 50px;
    /* Adjust the width to your desired size */
    height: 50px;
    /* Adjust the height to your desired size */
}

.card {
    border: none;
}

@media (max-width: 575.98px) {
    .services .col {
        width: 100%;
    }

        .home-section {
            height: auto;
        }
    
    .card-body {
        width: 280px;
        justify-content: center;
        align-items: center;
        margin-left: none !important;
        margin: none !important;
        padding-Left: none !important;
        padding-right: none !important;
    }
}
/* 
@media (min-width: 576px) and (max-width: 767.98px) {
    .services .col {
        width: 50%;
    }

        .home-section {
            height: auto;
        }
}

@media (max-width: 414px) {
    .services .col {
        width: 100%;
    }

    .home-section {
        height: auto;
    }

    .card-body {
        width: 280px;
        justify-content: center;
        align-items: center;
        margin-left: none !important;
        margin: none !important;
        padding-Left: none !important;
        padding-right: none !important;
    }
}

@media (max-width: 414px) {
    .mb-4 {
        margin-bottom: 1.5rem !important;
        justify-content: center;
        margin-left: -30px;
    }
} */`, "",{"version":3,"sources":["webpack://./src/style/Services.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,0CAA0C;IAC1C,YAAY;IACZ,2CAA2C;AAC/C;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;IACf;;QAEI;YACI,YAAY;QAChB;;IAEJ;QACI,YAAY;QACZ,uBAAuB;QACvB,mBAAmB;QACnB,4BAA4B;QAC5B,uBAAuB;QACvB,6BAA6B;QAC7B,8BAA8B;IAClC;AACJ;AACA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAqCG","sourcesContent":[".icon {\n    width: 50px;\n    /* Adjust the width to your desired size */\n    height: 50px;\n    /* Adjust the height to your desired size */\n}\n\n.card {\n    border: none;\n}\n\n@media (max-width: 575.98px) {\n    .services .col {\n        width: 100%;\n    }\n\n        .home-section {\n            height: auto;\n        }\n    \n    .card-body {\n        width: 280px;\n        justify-content: center;\n        align-items: center;\n        margin-left: none !important;\n        margin: none !important;\n        padding-Left: none !important;\n        padding-right: none !important;\n    }\n}\n/* \n@media (min-width: 576px) and (max-width: 767.98px) {\n    .services .col {\n        width: 50%;\n    }\n\n        .home-section {\n            height: auto;\n        }\n}\n\n@media (max-width: 414px) {\n    .services .col {\n        width: 100%;\n    }\n\n    .home-section {\n        height: auto;\n    }\n\n    .card-body {\n        width: 280px;\n        justify-content: center;\n        align-items: center;\n        margin-left: none !important;\n        margin: none !important;\n        padding-Left: none !important;\n        padding-right: none !important;\n    }\n}\n\n@media (max-width: 414px) {\n    .mb-4 {\n        margin-bottom: 1.5rem !important;\n        justify-content: center;\n        margin-left: -30px;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
