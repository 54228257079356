// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .row-daniel {
    margin-left: 400px;
} */


/* Team.css */

/* Custom styles for centering the image and adding a left margin */
/* .img-daniel {
    margin-left: -90px;
    



/* .img-team {
    margin-left: -80px;
} */

/* .row-teamdna {
    width: 600px;
} */

    

/* @media (max-width: 414px) {
.team {
    width:330px;
    align-items: center;
    margin-left: 30px;
}

.img-daniel {
    margin-left: 65px;
    align-items: center;
}
} */

/* @media screen and (max-width: 767px) {
    .mySwiper {
        width: 90%;
    }

} */`, "",{"version":3,"sources":["webpack://./src/style/Team.css"],"names":[],"mappings":"AAAA;;GAEG;;;AAGH,aAAa;;AAEb,mEAAmE;AACnE;;;;;;;;GAQG;;AAEH;;GAEG;;;;AAIH;;;;;;;;;;;GAWG;;AAEH;;;;;GAKG","sourcesContent":["/* .row-daniel {\n    margin-left: 400px;\n} */\n\n\n/* Team.css */\n\n/* Custom styles for centering the image and adding a left margin */\n/* .img-daniel {\n    margin-left: -90px;\n    \n\n\n\n/* .img-team {\n    margin-left: -80px;\n} */\n\n/* .row-teamdna {\n    width: 600px;\n} */\n\n    \n\n/* @media (max-width: 414px) {\n.team {\n    width:330px;\n    align-items: center;\n    margin-left: 30px;\n}\n\n.img-daniel {\n    margin-left: 65px;\n    align-items: center;\n}\n} */\n\n/* @media screen and (max-width: 767px) {\n    .mySwiper {\n        width: 90%;\n    }\n\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
