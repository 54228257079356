// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-col {
    margin: 0 !important;
}

/* Di file Project.css atau CSS terpisah Anda */
.img-container {
    position: relative;
    overflow: hidden;
}

.img-hover-zoom {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.img-hover-zoom:hover {
    transform: scale(1.1);
}


/* Responsif */
@media (max-width: 768px) {
    .img-container {
        /* Sesuaikan lebar sesuai kebutuhan untuk perangkat mobile */
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }
}

/* @media (max-width: 414px) {
.project .container {
        overflow: hidden;
    }

    .project .row {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
    }

    .project .col {
        scroll-snap-align: start;
        flex: 0 0 auto;
        width: 100%;
    }

    .project .col img {
        width: 100%;
        height: auto;
    }
} */`, "",{"version":3,"sources":["webpack://./src/style/Project.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA,+CAA+C;AAC/C;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;AACzB;;;AAGA,cAAc;AACd;IACI;QACI,4DAA4D;QAC5D,WAAW;QACX,gBAAgB;QAChB,cAAc;IAClB;AACJ;;AAEA;;;;;;;;;;;;;;;;;;;;;;GAsBG","sourcesContent":[".project-col {\n    margin: 0 !important;\n}\n\n/* Di file Project.css atau CSS terpisah Anda */\n.img-container {\n    position: relative;\n    overflow: hidden;\n}\n\n.img-hover-zoom {\n    width: 100%;\n    height: auto;\n    transition: transform 0.3s ease;\n}\n\n.img-hover-zoom:hover {\n    transform: scale(1.1);\n}\n\n\n/* Responsif */\n@media (max-width: 768px) {\n    .img-container {\n        /* Sesuaikan lebar sesuai kebutuhan untuk perangkat mobile */\n        width: 100%;\n        max-width: 300px;\n        margin: 0 auto;\n    }\n}\n\n/* @media (max-width: 414px) {\n.project .container {\n        overflow: hidden;\n    }\n\n    .project .row {\n        display: flex;\n        flex-wrap: nowrap;\n        overflow-x: auto;\n        scroll-snap-type: x mandatory;\n    }\n\n    .project .col {\n        scroll-snap-align: start;\n        flex: 0 0 auto;\n        width: 100%;\n    }\n\n    .project .col img {\n        width: 100%;\n        height: auto;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
